$(document).ready(() => {
    let windowWidth = $(window).width();
    console.log(windowWidth);

    //mobile menu
    $('.js_hamburger').on('click', function(){
        $('.header').toggleClass('header--menu-opened');
    });


    //gallery init
    let gallery = new Swiper('.gallery__container', {
        navigation: {
            nextEl: '#gallery-next',
            prevEl: '#gallery-prev'
        }
    });

    gallery.on('slideChange', function(){
        let active = gallery.activeIndex;
        let slides = gallery.slides;
        let total = slides.length - 1;

        if (active !== 0) {
            $('#gallery-prev .gallery__navigation-img').attr('src', slides[active - 1].children[0].src);
        }

        if (active < total) {
            $('#gallery-next .gallery__navigation-img').attr('src', slides[active + 1].children[0].src);
        }
    });

    //scroll to
    $('.js_scroll-to').on('click', function(e) {
        e.preventDefault();
        scrollTo($($(this).attr('href')));
        $('.header').removeClass('header--menu-opened');
    });

    function scrollTo(el) {
        let top = el.offset().top;
        if (windowWidth < 768) {
            top = el.offset().top - $('.header').outerHeight();
        }
        $('html, body').stop().animate({
            scrollTop: top
        }, 800);
    }

    //show on scroll
    function isElementInViewport(el) {
        let top = el.offsetTop;
        let left = el.offsetLeft;
        let width = el.offsetWidth;
        let height = el.offsetHeight;

        while(el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top < (window.pageYOffset + window.innerHeight) &&
            left < (window.pageXOffset + window.innerWidth) &&
            (top + height) > window.pageYOffset &&
            (left + width) > window.pageXOffset
        );
    }

    function checkElementInViewportAndSetClass(time){
        $('.js_animate').each(function () {
            if (isElementInViewport(this) === true) {
                let $t = $(this);
                window.setTimeout(function(){
                    $t.addClass('show');
                }, time);
            }
        });
    }

    $(window).scroll(function () {
        checkElementInViewportAndSetClass(500);
    });

    checkElementInViewportAndSetClass(0);
});
